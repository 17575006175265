import streams from 'config/streams.json';
import AudioType from 'models/Audio/AudioType';

export interface StreamSettings {
  showUrl: string;
  playlistUrl: string;
  stream: AudioType;
}

export function getStreamSettings(programId: string): StreamSettings {
  const stream =
    streams.find((item) => item.programId === programId) || streams[0];

  return {
    showUrl: `https://nowplaying.publicradio.org/${stream.programId}/schedule`,
    playlistUrl: `https://nowplaying.publicradio.org/${stream.programId}/playlist`,
    stream
  };
}
