import React, { FC } from 'react';

interface Props {
  elementClass?: string;
  fillColor?: string | undefined | null;
}

const IconNowPlaying: FC<Props> = ({ fillColor }) => {
  let currentProgram;

  switch (fillColor) {
    case 'the-current':
      currentProgram = 'var(--color-bkgd-lt)';
      break;
    case 'carbon-sound':
      currentProgram = 'var(--color-bkgd-lt)';
      break;
    case 'radio-heartland':
      currentProgram = '#89421B';
      break;
    default:
      currentProgram = 'var(--color-bkgd-lt)';
  }

  return (
    <svg
      width="17"
      height="23"
      viewBox="0 0 17 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 22V8.875M8.5 22V1M1 22V14.125"
        stroke={currentProgram}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconNowPlaying;
