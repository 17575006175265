import React, { FC, ReactElement, useContext } from 'react';
import { AudioContext, AudioState } from 'context/AudioContext';
import Icon from '../Icon';
import AudioType from 'models/Audio/AudioType';
import findAudioSource from 'utils/audio/findAudioSource';
import { LinkProps } from 'next/link';
// import { useRouter } from 'next/router';
// import { usePathname } from 'next/navigation';

interface Props extends AudioType {
  className?: string;
  label?: string;
  playIcon?: ReactElement;
  pauseIcon?: ReactElement;
  link?: LinkProps;
  resourceType?: string;
}

const AudioButton: FC<Props> = (audio) => {
  const {
    id,
    programId,
    title,
    className,
    label,
    playIcon,
    pauseIcon,
    link,
    resourceType
  } = audio;

  const context = useContext(AudioContext);
  // const router = useRouter();
  // const pathname = usePathname();
  const audioSrc = findAudioSource(audio);

  if (!audioSrc) return null;

  return (
    <>
      <button
        type="button"
        className={className}
        onClick={(e) => {
          e.preventDefault;
          // If there is a link, and the audio for this button is not already playing, go to the link
          if (
            link &&
            !(context.source === audioSrc && context.playerInstance?.isPlaying)
          ) {
            history.replaceState(null, '', `${link.href}`);
          }
          const songId = id ? String(id) : '';
          context.handleAudioButtonClick &&
            context.handleAudioButtonClick(
              audioSrc,
              title,
              label || '',
              programId,
              undefined,
              songId,
              resourceType
            );
        }}
      >
        <PlayPauseIcon
          audioState={context}
          source={audioSrc}
          playIcon={playIcon}
          pauseIcon={pauseIcon}
        />
        {label}
      </button>
    </>
  );
};

interface IconProps {
  audioState: AudioState;
  source: string;
  playIcon?: ReactElement;
  pauseIcon?: ReactElement;
}

const PlayPauseIcon: FC<IconProps> = ({
  audioState,
  source,
  playIcon,
  pauseIcon
}) => {
  if (audioState.source === source && audioState.isAudioPlaying) {
    return (
      pauseIcon || (
        <>
          <Icon name="pause" />
          <span className="invisible">Pause</span>
        </>
      )
    );
  } else {
    return (
      playIcon || (
        <>
          <Icon name="play" />
          <span className="invisible">Play</span>
        </>
      )
    );
  }
};

export default AudioButton;
