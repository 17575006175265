import { useEffect, useState } from 'react';
import NowPlayingClientFactory from 'utils/NowPlayingFactory';

import { toSentenceText, getHostImage, ImageReturnObject } from 'utils/hosts';
import { ScheduleData, PlaylistData, Song } from 'types/SongScheduleData';
import {
  getStreamSettings,
  StreamSettings
} from 'components/NowPlaying/StreamSettings';
import AudioType from 'models/Audio/AudioType';
import { SCHEDULE_VALID_STREAMS } from 'components/Streams/StreamLink';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

interface ShowData {
  name?: string;
  image?: ImageReturnObject | undefined;
  hosts?: string;
  uses_playlist?: boolean;
  service?: string;
}

interface UseNowplayingProps {
  streamId?: string;
  songs: Song[];
}

interface NowplayingInfo {
  stream: AudioType;
  showData?: ShowData;
  songData?: Song[];
}

const useNowplaying = ({
  streamId = 'the-current',
  songs
}: UseNowplayingProps): NowplayingInfo => {
  const { stream }: StreamSettings = getStreamSettings(streamId);

  const nowplayingClient = NowPlayingClientFactory({
    server:
      process.env.NEXT_PUBLIC_NOWPLAYING_SERVER ||
      'wss://nowplayingv2.publicradio.org'
  });

  const [showData, setShowData] = useState<ShowData>();
  const [songData, setSongData] = useState<Song[]>(songs);

  useEffect(() => {
    const scheduleRegistration = SCHEDULE_VALID_STREAMS.includes(streamId)
      ? nowplayingClient.register_callback(
          streamId,
          'schedule',
          function (data: ScheduleData) {
            findCurrentShow(data);
          }
        )
      : { unregister: () => undefined };

    const playlistRegistration = nowplayingClient.register_callback(
      streamId,
      'playlist',
      function (data: PlaylistData) {
        if (data && data.plays) {
          setSongData(
            data.plays?.map((play) => {
              return { ...play.song, played_at: play.played_at };
            })
          );
        }
      }
    );

    return () => {
      scheduleRegistration.unregister();
      playlistRegistration.unregister();
    };
  }, [streamId]);

  function findCurrentShow(data: ScheduleData) {
    let currentShow;
    try {
      currentShow = data?.schedule?.find((show) => {
        return dayjs().isBetween(show.start_dtim, show.end_dtim);
      });
    } catch {
      currentShow = null;
    }

    const hosts = currentShow ? toSentenceText(currentShow.people) : undefined;

    setShowData({
      name: currentShow ? currentShow.shows[0].name : undefined,
      image: currentShow ? getHostImage(currentShow.people) : undefined,
      hosts,
      service: stream ? stream.title : undefined,
      uses_playlist: currentShow
        ? currentShow.shows[0].uses_playlist
        : undefined
    });
  }

  return { stream, showData, songData };
};

export default useNowplaying;
