import React, { FC } from 'react';
import { Song } from 'types/SongScheduleData';
import { defaultAlbumThumbnail } from 'config/defaultData/ImageDefault';
import SongLink from '../SongLink/SongLink';
import dayjs from 'dayjs';
import AddFavoriteWrapper from 'components/Data/AddFavoriteWrapper';

interface Props extends Song {
  service: string;
}

const NowPlayingSong: FC<Props> = (props) => {
  return (
    <li className="playlist-card">
      <div>
        <div className="playlist-image">
          <SongLink service={props.service} song_id={props.song_id}>
            <img
              src={props.art_url || defaultAlbumThumbnail}
              alt="album art"
              width="185"
              height="185"
            />
          </SongLink>
        </div>
        <h4 className="playlist-title type-strong">
          <SongLink service={props.service} song_id={props.song_id}>
            {props.title}
          </SongLink>
        </h4>
        {props.artist && (
          <div className="playlist-artist">
            <SongLink service={props.service} song_id={props.song_id}>
              {props.artist}
            </SongLink>
          </div>
        )}
        {props.album && (
          <div className="playlist-artist">
            <SongLink service={props.service} song_id={props.song_id}>
              {props.album}
            </SongLink>
          </div>
        )}
      </div>
      <div className="playlist-save-container">
        <div className="playlist-time">
          {dayjs(props.played_at).format('h:mm a')}
        </div>
        <AddFavoriteWrapper
          title={props.title}
          contentArea={
            process.env.NEXT_PUBLIC_CONTENT_AREA_SLUG ?? 'the-current'
          }
          resourceType="song"
          slug=""
          id={props.song_id?.toString() ?? ''}
          album={props.album ?? undefined}
          artist={props.artist ?? undefined}
          duration={props.duration ?? undefined}
          art_url={props.art_url ?? undefined}
        />
      </div>
    </li>
  );
};

export default NowPlayingSong;
