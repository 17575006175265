import Link from 'next/link';
import React, { FC } from 'react';

interface Props {
  song_id: number | string | null;
  service: string;
  children: React.ReactNode;
}

const SongLink: FC<Props> = ({ song_id, service, children }) => {
  if (song_id) {
    return (
      <Link prefetch={false} href={`/song/${service}/${song_id}`}>
        {children}
      </Link>
    );
  }
  return <>{children}</>;
};

export default SongLink;
