import PersonFromSchedule from 'types/PersonFromSchedule';

export interface ImageReturnObject {
  url: string;
  alt: string;
  caption: string;
}

export function toSentenceText(arr: PersonFromSchedule[]): string {
  let hosts = '';
  arr.forEach((person, idx) => {
    if (idx === 0) {
      hosts += person.name;
    } else if (idx === arr.length - 1) {
      hosts += ` and ${person.name}`;
    } else {
      hosts += `, ${person.name}`;
    }
  });
  return hosts;
}

export function getHostImage(
  arr: PersonFromSchedule[]
): ImageReturnObject | undefined {
  if (arr[0] && arr[0].image) {
    const image = arr[0].image;
    const imageInstance = image.aspect_ratios.square.instances[0];

    return {
      url: imageInstance.url,
      alt: image.alt_text,
      caption: image.caption
    };
  }
}
