import React, { FC } from 'react';
import LinkNoPrefetch from 'components/LinkNoPrefetch/LinkNoPrefetch';

interface Props {
  streamId: string; // e.g. 'classical-mpr'
  scroll?: boolean;
  className?: string;
  day?: string;
  children?: React.ReactNode;
}

// only streams that appear on both: https://scheduler.publicradio.org/api/v1/services and
// https://playlist.publicradio.org/api/v1/services can have schedules and playlists
// local show or rock the cradle does not have a valid schedule yet

export const SCHEDULE_VALID_STREAMS = [
  'the-current',
  'radio-heartland',
  'purple-current'
];
export const PLAYLIST_VALID_STREAMS = [
  'the-current',
  'local-current',
  'radio-heartland',
  'rockthecradle',
  'purple-current',
  'current-holiday',
  'carbon-sound',
  'the-siren'
];

export const StreamScheduleLink: FC<Props> = ({
  streamId,
  scroll,
  className,
  day,
  children
}) => {
  if (!SCHEDULE_VALID_STREAMS.includes(streamId)) {
    return null;
  }

  const linkProps = {
    href: day ? `/schedule/${streamId}/${day}` : `/schedule/${streamId}`,
    scroll
  };

  return (
    <LinkNoPrefetch {...linkProps} className={className}>
      {children}
    </LinkNoPrefetch>
  );
};

export const StreamPlaylistLink: FC<Props> = ({
  streamId,
  scroll,
  className,
  day,
  children
}) => {
  if (!PLAYLIST_VALID_STREAMS.includes(streamId)) {
    return null;
  }

  const linkProps = {
    href: day ? `/playlist/${streamId}/${day}` : `/playlist/${streamId}`,
    scroll
  };

  return (
    <LinkNoPrefetch {...linkProps} className={className}>
      {children}
    </LinkNoPrefetch>
  );
};
