'use client';
import React, { FC, useContext, useState } from 'react';
import { IoIosPause } from '@react-icons/all-files/io/IoIosPause';
import { IoIosPlay } from '@react-icons/all-files/io/IoIosPlay';
import sty from 'styles/modules/NowPlaying.module.css';
import Link from 'next/link';

import {
  StreamPlaylistLink,
  StreamScheduleLink,
  SCHEDULE_VALID_STREAMS
} from '../Streams/StreamLink';
import useNowplaying from 'hooks/useNowplaying';
import { Song } from 'types/SongScheduleData';
import dynamic from 'next/dynamic';
import IconNowPlaying from '../Icon/IconNowPlaying';
import { AudioContext } from 'context/AudioContext';
import AdvertisementBanner from 'components/Advertisement/AdvertisementBanner';
import NowPlayingSong from './NowPlayingSong';
import IconOpenTabGrey from '../Icon/IconOpenTabGrey';
import AudioButton from 'components/AudioButton/AudioButton';
import IconCalendar from 'components/Icon/IconCalendar';
import IconMusicNote from 'components/Icon/IconMusicNote';
import IconPause from 'components/Icon/IconPause';
import IconPlay from 'components/Icon/IconPlay';

export interface NowPlayingProps {
  listenPage?: boolean;
  streamId?: string;
  songs: Song[];
  className?: string;
}

export interface Stream {
  slug: string;
  name: string;
  image: string;
  tabClass: string;
  mainClass: string;
}

const streams: Stream[] = [
  {
    slug: 'the-current',
    name: 'The Current',
    image: '/images/the-current-logo-text.svg',
    tabClass: 'the-current-logo-bg',
    mainClass: 'the-current-main'
  },
  {
    slug: 'carbon-sound',
    name: 'Carbon Sound',
    image: '/images/carbon-sound-logo.svg',
    tabClass: 'carbon-sound-purple-bg',
    mainClass: 'carbon-sound-main'
  },
  {
    slug: 'radio-heartland',
    name: 'Radio Heartland®',
    image: '/images/radio-heartland-logo.svg',
    tabClass: 'radio-heartland-beige-bg',
    mainClass: 'radio-heartland-main'
  }
];

const DynamicAudioWrapper = dynamic(
  () => import('../AudioButton/AudioWrapper')
);

const NowPlaying: FC<NowPlayingProps> = ({ listenPage, songs, className }) => {
  const { programId, setProgramId } = useContext(AudioContext);
  const streamId = programId ?? 'the-current';
  const [currentStream, setCurrentStream] = useState(streams[0]);
  const { stream, showData, songData } = useNowplaying({ streamId, songs });
  const currentSong = songData && songData[0];
  const currentSongArtist = currentSong?.artist
    ? `by ${currentSong.artist}`
    : '';
  let currentProgram;

  const hasSchedule = SCHEDULE_VALID_STREAMS.includes(streamId);

  const handleStreamChange = (
    e: React.MouseEvent<HTMLInputElement>,
    stream: Stream
  ) => {
    const target = e.target as HTMLInputElement;
    const id = target.value;
    setProgramId && setProgramId(id);
    setCurrentStream(stream);
  };

  switch (programId) {
    case 'the-current':
      currentProgram = 'The Current';
      break;
    case 'carbon-sound':
      currentProgram = 'Carbon Sound';
      break;
    case 'radio-heartland':
      currentProgram = 'Radio Heartland®';
      break;
    default:
      currentProgram = 'The Current';
  }

  return (
    <div className={`container ${className}`}>
      {!listenPage ? (
        <section className="playlist playlist_home" id="playlist">
          <form className={sty.playlist_select}>
            <fieldset name="now-playing-stream" id="now-playing-stream">
              {streams.map((stream) => {
                return (
                  <label
                    key={stream.slug}
                    htmlFor={stream.slug}
                    className={stream.tabClass}
                  >
                    <img alt={stream.name} src={stream.image} />
                    <input
                      type="radio"
                      name="stream"
                      key={stream.slug}
                      value={stream.slug}
                      id={stream.slug}
                      onClick={(event) => {
                        handleStreamChange(event, stream);
                      }}
                    />
                  </label>
                );
              })}
            </fieldset>
          </form>
          <div className={`${sty.playlist_main} ${currentStream.mainClass}`}>
            <div className={sty.playlist_inner_container}>
              <div className={sty.playlist_header}>
                <div className={sty.playlist_header_left}>
                  <div>
                    <IconNowPlaying fillColor={programId} />
                  </div>
                  <div>
                    <h2>Now Playing - {currentProgram}</h2>
                  </div>
                </div>
                <div className={sty.playlist_header_right}>
                  <div className={sty.playlist_header_right_links}>
                    <div className={sty.playlist_header_right_links_link}>
                      <Link
                        href={`/playlist/${programId}`}
                        className={sty[`link-color-${programId}`]}
                        prefetch={false}
                        passHref
                      >
                        <IconMusicNote
                          height="23"
                          width="22"
                          color={programId}
                        />
                        <span>View full playlist</span>
                      </Link>
                    </div>
                    {programId !== 'carbon-sound' && (
                      <div className={sty.playlist_header_right_links_link}>
                        <Link
                          href={`/schedule/${programId}`}
                          className={sty[`link-color-${programId}`]}
                          prefetch={false}
                          passHref
                        >
                          <IconCalendar color={programId} />{' '}
                          <span>Schedule</span>
                        </Link>
                      </div>
                    )}
                  </div>

                  <div className={sty.playlist_header_right_audio}>
                    <AudioButton
                      {...stream}
                      className="btn"
                      playIcon={
                        <div className={sty.playlist_header_right_links_link}>
                          <IconPlay />
                          <span>Listen live</span>
                        </div>
                      }
                      pauseIcon={
                        <div className={sty.playlist_header_right_links_link}>
                          <IconPause height="20" width="17" />
                          <span>Listen live</span>
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>
              <div className={sty.playlist_grid_container}>
                <div className={sty.playlist_on_air}>
                  {programId === 'the-current' && (
                    <div className={sty.playlist_on_air_current}>
                      <div>
                        <picture>
                          <source
                            type="image/webp"
                            srcSet={showData?.image?.url}
                          />
                          <img
                            src={'images/default-album-art.png'}
                            height="400"
                            width="400"
                            alt={''}
                          />
                        </picture>
                      </div>
                      <div className={sty.playlist_on_air_content_current}>
                        <ul>
                          <li>on-air live broadcast</li>
                          <li>{showData && showData.name}</li>
                          <li>with {showData && showData.hosts}</li>
                        </ul>
                      </div>
                    </div>
                  )}
                  {programId === 'carbon-sound' && (
                    <Link
                      href="https://www.carbonsound.fm/"
                      prefetch={false}
                      passHref
                      target="_blank"
                      aria-label="Opens new link in a tab - Visit Carbon Sound online"
                    >
                      <div className={sty.carbon_sound_logo} />
                      <div className={sty.playlist_on_air_content}>
                        <p>
                          Visit Carbon Sound online. <IconOpenTabGrey />
                        </p>
                      </div>
                    </Link>
                  )}
                  {programId === 'radio-heartland' && (
                    <Link
                      href="https://www.thecurrent.org/playlist/radio-heartland"
                      prefetch={false}
                      passHref
                    >
                      <div className={sty.radio_heartland_container}>
                        <div className={sty.radio_heartland_logo} />
                      </div>
                      <div className={sty.playlist_on_air_content}>
                        <p>Visit Radio Heartland®.</p>
                      </div>
                    </Link>
                  )}
                </div>
                <div className={sty.playlist_stream}>
                  <div className={sty.playlist_stream_inner}>
                    <div className="playlist-home">
                      <ul className="playlist tiles">
                        {songData?.slice(0, -1).map((song) => (
                          <NowPlayingSong
                            key={`${streamId}-${
                              song.song_id || song.played_at
                            }`}
                            {...song}
                            service={currentStream.slug}
                          />
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section>
          <div>
            <p>On Air Now</p>
            {currentStream.name && <h2>{currentStream.name}</h2>}
            {currentSong?.title && showData?.uses_playlist !== false && (
              <div>
                <strong>{currentSong.title}</strong>
                {currentSong?.artist && currentSongArtist}
              </div>
            )}
            <div>
              <AudioButton
                {...stream}
                className="btn"
                playIcon={
                  <>
                    <IoIosPlay /> Listen live
                  </>
                }
                pauseIcon={
                  <>
                    <IoIosPause /> Listen live
                  </>
                }
              />
              <div>
                <StreamPlaylistLink streamId={streamId}>
                  On-Air Playlist
                </StreamPlaylistLink>
                {hasSchedule && (
                  <>
                    {' '}
                    |{' '}
                    <StreamScheduleLink streamId={streamId}>
                      See Full Schedule
                    </StreamScheduleLink>
                  </>
                )}
              </div>
            </div>
          </div>
          <div>
            <DynamicAudioWrapper {...stream}>
              <div>
                <IoIosPlay />
              </div>
            </DynamicAudioWrapper>
          </div>
        </section>
      )}
    </div>
  );
};

export default NowPlaying;
